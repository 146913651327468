<template>
  <el-container class="container">
    <el-header class="header">
      <header-top showTit="返回" :isLink="true"></header-top>
    </el-header>
    <!-- answerStatusList：{{answerStatusList}} -->
    <!-- testTask.examUser.reviewTime：{{testTask.examUser}} -->
    <el-main class="main">
      <div class="task-con clearfix">
        <div class="task-left fl">
          <div class="panel">
            <span v-text="testTask.title" class="task-tit"></span>
            <el-row class="mt-15">
              <span class="ml-10">
                交卷时间：
                <span>{{testTask.examUser.finishTime | dateFormat}}</span>
              </span>
              <span class="ml-10">
                用时：
                <span
                  v-text="changeMinutes(this.testTask.examUser.createTime, this.testTask.examUser.finishTime)"
                ></span>
              </span>
            </el-row>
            <!-- 得分 -->
            <el-row class="mt-20 mb-20 score-body">
              <el-col :span="6">
                <span class="ml-10">
                  <font class="score-tit" v-text="Number(testTask.examUser.score)"></font>分
                </span>
                <p class="mt-15">
                  总分
                  <span v-text="testScores"></span>分
                </p>
              </el-col>
              <el-col :span="12">
                <ul class="score-list">
                  <li>
                    <span>答对</span>
                    <span class="succe-span">{{correctRs}}道</span>
                  </li>
                  <li>
                    <span>答错</span>
                    <span class="dange-span">{{mistakeRs}}道</span>
                  </li>
                  <li>
                    <span>未批阅</span>
                    <span class="audit-span">{{notReadRs}}道</span>
                  </li>
                </ul>
              </el-col>
            </el-row>
            <!-- <el-form :model="examForm" label-width="120px" style="margin: 10px 0;">
              <el-form-item label="评语：" v-if="!testTask.examUser.reviewTime">
                <my-tinymce v-model="examForm.remark" height="130px"></my-tinymce>
              </el-form-item>
            </el-form> -->
          </div>
          <div
            class="panel"
            v-for="(item, index) in testTask.questionList"
            :key="index"
            :id="'anchor_'+ (index+1)"
          >
            <el-row class="topicTit">
              <span class="top-num">{{index+1}}.</span>
              <span class="top-type">【 {{item.question.type}} 】</span>
              <span class="top-score">{{item.score}} 分</span>
            </el-row>
            <el-row>
              <el-row v-html="item.question.stem" class="task-title"></el-row>
            </el-row>

            <ul class="label-con">
              <li class="label-list" v-for="(label, index) in item.question.itemList" :key="index">
                <span v-text="optionLetterName(index) + '.'"></span>
                <span v-html="label.content.replace(/<[/]?p>/g, '')"></span>
              </li>
            </ul>
            <template v-if="item.question.type === '单选'">
              <el-row>
                <span>
                  正确答案是：
                  <span v-for="(label, index) in item.question.itemList" :key="index">
                    <template v-if="label.correctAnswer === true">
                      <span v-text="optionLetterName(index)" class="answer-color"></span>
                    </template>
                  </span>
                </span>

                <span class="ml-15">
                  学员答案是：
                  <template v-if="Boolean(testTask.questionList[index].answer)">
                    <span v-for="(ele, idx) in item.question.itemList" :key="idx">
                      <template
                        v-if="JSON.parse(testTask.questionList[index].answer)[0] === ele.id"
                      >
                        <span v-text="optionLetterName(idx)"></span>
                      </template>
                    </span>
                  </template>
                </span>
              </el-row>
            </template>

            <template v-if="item.question.type === '多选'">
              <el-row>
                <span>
                  正确答案是：
                  <span v-for="(label, index) in item.question.itemList" :key="index">
                    <template v-if="label.correctAnswer === true">
                      <span v-text="optionLetterName(index)" class="answer-color"></span>
                    </template>
                  </span>
                </span>

                <span class="ml-15">
                  学员答案是：
                  <template v-if="Boolean(testTask.questionList[index].answer)">
                    <span v-for="(ele, idx) in item.question.itemList" :key="idx">
                      <span
                        v-for="(item) in JSON.parse(testTask.questionList[index].answer)"
                        :key="item.id"
                      >
                        <template v-if="ele.id === item">
                          <span v-text="optionLetterName(idx)"></span>
                        </template>
                      </span>
                    </span>
                  </template>
                </span>
              </el-row>
            </template>

            <template v-if="item.question.type === '填空'">
              <el-tabs type="border-card">
                <el-tab-pane label="学员答案">
                  <template>
                    <template v-if="Boolean(testTask.questionList[index].answer)">
                      <span>{{testTask.questionList[index].answer}}</span>
                    </template>
                  </template>
                </el-tab-pane>
                <el-tab-pane label="正确答案">
                  <span v-for="(label, index) in JSON.parse(item.question.correctAnswer)" :key="index">
                    {{label}}
                  </span>
                </el-tab-pane>
                <el-tab-pane label="得分">
                  <el-row v-text="item.myScore" style="margin: 10px 0;" class="myScore"></el-row>
                </el-tab-pane>
              </el-tabs>
            </template>

            <template v-if="item.question.type === '简答'">
              <el-row>
                <el-tabs type="border-card">
                  <el-tab-pane label="学员答案">
                    <template>
                      <pre v-text="item.answer"></pre>
                    </template>
                  </el-tab-pane>
                  <el-tab-pane label="正确答案">
                    <el-row v-html="item.question.correctAnswer" style="margin: 10px 0;"></el-row>
                  </el-tab-pane>
                  <el-tab-pane label="得分">
                    <el-row v-text="item.myScore" style="margin: 10px 0;" class="myScore"></el-row>
                  </el-tab-pane>
                </el-tabs>
              </el-row>
              <template v-if="!item.reviewTime">
                <el-form
                  :model="item"
                  ref="topicForm"
                  label-width="120px"
                  style="margin-top: 10px;"
                >
                  <el-form-item
                    label="得分："
                    prop="topicScore"
                    :rules="{required: true, message: '请输入分数', trigger: 'blur'}"
                  >
                    <el-input
                      v-model="item.topicScore"
                      style="width: 160px;"
                      @blur="blurFun(item, index)"
                      @input="optionChange"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="评语：">
                    <my-tinymce v-model="item.topicRemark" height="130px"></my-tinymce>
                  </el-form-item>
                </el-form>
              </template>
            </template>

            <el-row class="mt-15">
              <span>题目解析：</span>
              <el-row class="mt-15">
                <template
                  v-if="!item.question.analysis.replace(/<([a-z]+?)(?:\s+?[^>]*?)?>\s*?<br><\/\1>/ig, '')"
                >
                  <p class="analysis-span">无解析内容</p>
                </template>
                <template v-else>
                  <p v-html="item.question.analysis" class="analysis-span"></p>
                </template>
              </el-row>
            </el-row>
          </div>
        </div>
        <div class="task-right fl" :style="{'height': screenHeight + 'px'}">
          <div class="task-fixed">
            <h5 class="card-tit">答题卡</h5>
            <ul class="card-list clearfix">
              <li
                v-for="(item, index) of answerStatusList"
                :key="index"
                @click="skipAnchor(index+1)"
                :class="switchCard(item)"
              >{{index+1}}</li>
            </ul>
            <!-- 答题卡颜色对应的状态 -->
            <el-row class="card-explain">
              <span>
                <i class="bg-card-success"></i>正确
              </span>
              <span>
                <i class="bg-card-error"></i>错误
              </span>
              <span>
                <i class="bg-card-default"></i>未批阅
              </span>
            </el-row>
            <el-row style="text-align: center;">
              <el-button
                size="small"
                round
                @click="ReadOverPaper"
                type="primary"
                style="color: #212121;margin: 10px 0;"
                :disabled="Boolean(testTask.examUser.reviewTime)"
              >完成批阅</el-button>
            </el-row>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import getOptionName from '@/mixins/getOptionName'
import headerTop from '@/components/HeaderTop'
import myTinymce from '@/components/TinymceText'
import { getUserExam, examScore, questionScore } from '@/api/research/exam'
export default {
  mixins: [getOptionName],
  components: {
    headerTop,
    myTinymce
  },
  inject: ['reload'],
  data () {
    return {
      screenWidth: '', // 宽度
      screenHeight: '', // 高度
      answerList: [], // 答题卡-（数据）
      multipleOption: [], // 答题卡多选数据
      examInfo: {}, // 考试详细信息
      testTask: {
        examUser: {}
      },
      hr: 0,
      min: 0,
      sec: 0,
      testScores: 0, // 试卷总分
      correctRs: 0, // 正确结果个数
      mistakeRs: 0, // 错误结果个数
      notReadRs: 0, // 未批阅（客观题）个数
      TotalNum: 0, // 试卷总题目 数量
      answerStatusList: [], // 答题卡-状态
      taskID: '', // 任务id
      userID: '', // 用户id
      answerID: '', // 作答记录id
      examForm: {
        remark: ''
      },
      topicValid: [],
      shortAnswerList: []
    }
  },
  created () {
    // this.taskID = this.$route.query.id
    // this.userID = this.$route.query.userId
    this.answerID = this.$route.query.id
  },
  mounted () {
    this.getUserExamInfo()
  },
  methods: {
    // 题目锚点跳转
    skipAnchor (index) {
      let domAnchor = `#anchor_${index}`
      document.querySelector(domAnchor).scrollIntoView(true)
    },

    // 答题卡状态-渲染
    switchCard (state) {
      switch (state.answer) {
        case '未批阅':
          return 'bg-card-default'
        case '回答正确':
          return 'bg-card-success'
        case '回答错误':
          return 'bg-card-error'
        default:
          return ''
      }
    },

    optionChange () {
      this.$forceUpdate()
    },

    // 获取学员考试-试卷详细信息
    async getUserExamInfo (id) {
      const res = await getUserExam({
        id: this.answerID
        // examId: this.taskID,
        // studentId: this.userID
      })
      console.log('res', res.body)
      this.testTask = Object.assign({}, res.body)
      this.TotalNum = this.testTask.questionList.length // 试卷总题目数量
      this.testTask.questionList.forEach((item, index) => {
        if (item.question.type === '简答') {
          this.$set(item, 'topicScore', '')
          this.$set(item, 'topicRemark', '')
          this.shortAnswerList.push(item)
        }
        this.testScores += Number(item.score)
        this.testTask.examUser.questionUserList.forEach(
          (label, idx) => {
            if (
              item.questionId === label.questionId &&
              Boolean(label.answer)
            ) {
              item.answer = label.answer
              item.reviewTime = label.reviewTime
              item.teacherSay = label.teacherSay
              item.myScore = label.score
              let answerArr = []
              if (item.question.type === "填空") {
                // console.log('填空', item)
                // console.log('填空', label)
                if (Number(item.score) === Number(label.score)) {
                  this.correctRs++;
                  this.answerStatusList.push({
                    id: item.questionId,
                    answer: "回答正确"
                  });
                } else {
                  this.mistakeRs++;
                  this.answerStatusList.push({
                    id: item.questionId,
                    answer: "回答错误"
                  });
                }
              } else if (item.question.type !== '简答' && item.question.type !== '填空') {
                item.question.itemList.findIndex(list => {
                  // 从question.itemList中获取题目的正确选项
                  if (list.correctAnswer === true) {
                    answerArr.push(list.id)
                  }
                })
                if (
                  JSON.stringify(answerArr).replace(/ /g, '') ===
                  label.answer
                ) {
                  this.correctRs++
                  this.answerStatusList.push({
                    id: item.questionId,
                    answer: '回答正确'
                  })
                } else {
                  this.mistakeRs++
                  this.answerStatusList.push({
                    id: item.questionId,
                    answer: '回答错误'
                  })
                }
              } else if (item.question.type === "简答") {
                if (!label.reviewTime) {
                  this.notReadRs++
                  this.answerStatusList.push({
                    id: item.questionId,
                    answer: '未批阅'
                  })
                } else {
                  if (Number(item.myScore) === Number(item.score)) {
                    this.correctRs++
                    this.answerStatusList.push({
                      id: item.questionId,
                      answer: '回答正确'
                    })
                  } else {
                    this.mistakeRs++
                    this.answerStatusList.push({
                      id: item.questionId,
                      answer: '回答错误'
                    })
                  }
                }
              }
            } else if (
              item.questionId === label.questionId &&
              Boolean(!label.answer)
            ) {
              item.answer = label.answer
              item.reviewTime = label.reviewTime
              item.teacherSay = label.teacherSay
              item.myScore = label.score
              if (item.question.type !== '简答') {
                this.answerStatusList.push({
                  id: item.questionId,
                  answer: '回答错误'
                })
                this.mistakeRs++
              } else {
                if (!label.reviewTime) {
                  this.notReadRs++
                  this.answerStatusList.push({
                    id: item.questionId,
                    answer: '未批阅'
                  })
                } else {
                  if (Number(item.myScore) === Number(item.score)) {
                    this.correctRs++
                    this.answerStatusList.push({
                      id: item.questionId,
                      answer: '回答正确'
                    })
                  } else {
                    this.mistakeRs++
                    this.answerStatusList.push({
                      id: item.questionId,
                      answer: '回答错误'
                    })
                  }
                }
              }
            }
          }
        )
      })
    },

    // 计算答题时间
    changeMinutes (createTime, finishTime) {
      const now = Date.parse(new Date(createTime))
      const end = Date.parse(new Date(finishTime))
      const msec = end - now
      let hr =
        parseInt((msec / 1000 / 60 / 60) % 24) > 9
          ? parseInt((msec / 1000 / 60 / 60) % 24)
          : '0' + parseInt((msec / 1000 / 60 / 60) % 24)
      let min =
        parseInt((msec / 1000 / 60) % 60) > 9
          ? parseInt((msec / 1000 / 60) % 60)
          : '0' + parseInt((msec / 1000 / 60) % 60)
      let sec =
        parseInt((msec / 1000) % 60) > 9
          ? parseInt((msec / 1000) % 60)
          : '0' + parseInt((msec / 1000) % 60)
      let elapsedTime = hr + ':' + min + ':' + sec
      return elapsedTime
    },

    checkAdult (age) {
      return age === false
    },

    // 批阅试卷
    async ReadOverPaper () {
      if (this.$refs.topicForm) {
        this.topicValid = []
        let topicFormArr = []
        topicFormArr = this.$refs.topicForm
        topicFormArr.forEach((element, index) => {
          element.validate(valid => {
            if (valid) {
              this.topicValid.push(true)
            } else {
              this.topicValid.push(false)
            }
          })
        })
        let firstSite = this.topicValid.findIndex(this.checkAdult)
        if (firstSite !== -1) {
          window.$msg('请先批阅', 2)
          this.testTask.questionList.forEach((item, index) => {
            if (item.questionId === this.shortAnswerList[firstSite].questionId) {
              this.skipAnchor(index + 1)
            }
          })
        } else {
          let PromiseArr = []
          this.testTask.questionList.forEach(async (item, index) => {
            if (item.question.type === '简答') {
              // Promise.all([])
              let a = this.testQuesRead(item)
              PromiseArr.push(a)
            }
          })
          Promise.all(PromiseArr).then(res => {
            this.paperRemark().then(res => {
              if (res) {
                window.$msg('批阅成功')
                this.reload()
              }
            })
          })
        }
      } else {
        this.paperRemark().then(res => {
          if (res) {
            window.$msg('批阅成功')
            this.reload()
          }
        })
      }
    },

    async paperRemark () {
      const res = await examScore({
        euId: this.answerID,
        // examId: this.taskID,
        // userId: this.userID,
        teacherSay: this.examForm.remark
      })
      if (res.state === 'success') {
        return true
      } else {
        return false
      }
    },
    // 简答题批阅
    async testQuesRead (item) {
      const res = await questionScore({
        euId: this.answerID,
        questionId: item.questionId,
        // examId: item.examId,
        // userId: this.userID,
        score: item.topicScore,
        teacherSay: item.topicRemark
      })
      return res.state
    },

    blurFun (item, index) {
      var reg = /^[0-9]+.?[0-9]*$/ // 判断字符串是否为自然数 ，判断正整数用/^[1-9]+[0-9]*]*$/
      if (!reg.test(Number(item.topicScore))) {
        window.$msg('请输入有效的自然数', 2)
        this.testTask.questionList[index].topicScore = ''
        this.optionChange()
        return false
      } else if (item.score < Number(item.topicScore)) {
        window.$msg('请输入有效的分数', 2)
        this.testTask.questionList[index].topicScore = ''
        this.optionChange()
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.short-answer {
  font-size: 14px;
  color: rgb(51, 51, 51);
  margin: 10px 0;
}
.bg-card-default {
  border: 1px solid #909399 !important;
  background-color: #909399 !important;
  color: #fff !important;
}
.bg-card-success {
  border: 1px solid #4da54d !important;
  background-color: #4da54d !important;
  color: #fff !important;
}
.bg-card-error {
  border: 1px solid #ce433f !important;
  background-color: #ce433f !important;
  color: #fff !important;
}
.bg-card {
  border: 1px solid #f5c319 !important;
  background-color: #f5c319 !important;
  color: #fff !important;
}

.card-explain {
  padding-left: 5px;
  margin: 10px 0;
  span {
    display: inline-block;
    color: 14px;
    margin-right: 10px;
    i {
      display: inline-block;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      border-radius: 4px;
    }
  }
}

.task-tit {
  font-size: 24px;
  font-weight: 700;
  color: #444;
}
.task-con {
  width: 70%;
  min-width: 1300px;
  margin: 0 auto 0;
  padding-top: 10px;
  overflow: auto;
}
.task-left {
  width: 75%;
  height: 100%;
  overflow: auto;
  margin-right: 2%;
}
.task-right {
  position: relative;
}

.task-fixed {
  position: fixed;
  top: 70px;
  z-index: 999;
  width: 15%;
  background: #fff;
  box-sizing: border-box;
  padding: 15px 15px 20px 21px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.topicTit {
  font-size: 20px;
  color: #212121;
  margin-bottom: 10px;
  span {
    display: inline-block;
  }
  .top-num {
    margin-right: 10px;
  }
  .top-type {
    margin-right: 10px;
  }
}

.label-list {
  line-height: 30px;
  margin-bottom: 10px;
  width: 70%;
  box-sizing: border-box;
  padding-left: 20px;
}

.task-title {
  display: inline-block;
  color: #474747;
  margin-top: 10px;
  margin-bottom: 15px;
}

.card-tit {
  font-size: 18px;
  color: #212121;
}

.card-list {
  margin-top: 10px;
  li {
    float: left;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}

.bg-card {
  border: 1px solid #f5c319 !important;
  background-color: #f5c319 !important;
  color: #fff !important;
}

.panel {
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

/deep/ .el-radio-group {
  display: inline-block;
  margin-left: 10px;
  padding: 10px 15px;
  margin-bottom: 13px;
  &:hover {
    background: #eee;
  }
}

/deep/ .el-checkbox-group {
  padding-left: 20px;
  display: inline-block;
  margin-bottom: 13px;
}

.container {
  width: 100%;
  height: 100%;
  .header {
    width: 100%;
    display: flex;
    padding-left: 15px;
    align-items: center;
    background: #212121;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
  }
  .main {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0;
  }
}

.myScore {
  color: #CE433F;
  font-weight: 700;
}
// 得分
.score-body {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-tit {
  font-size: 40px;
  color: #f60;
}

.score-list {
  li {
    &:nth-child(n + 1) {
      span {
        border-bottom: 0;
      }
    }
    &:nth-child(3) {
      span {
        border-bottom: 1px solid #ddd;
      }
    }
    span {
      box-sizing: border-box;
      border: 1px solid #dddddd;
      display: inline-block;
      line-height: 40px;
      text-align: center;
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
        border-left: 0;
      }
    }
  }
}

.succe-span {
  color: #43bc60;
}
.dange-span {
  color: #ed3e3e;
}
.audit-span {
  color: #f60;
}

// 正确答案
.answer-color {
  color: #f5c319;
}

// 题目解析
.analysis-span {
  box-sizing: border-box;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  padding: 15px 0;
  padding-left: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 30px;
}
</style>
