var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c(
        "el-header",
        { staticClass: "header" },
        [_c("header-top", { attrs: { showTit: "返回", isLink: true } })],
        1
      ),
      _c("el-main", { staticClass: "main" }, [
        _c("div", { staticClass: "task-con clearfix" }, [
          _c(
            "div",
            { staticClass: "task-left fl" },
            [
              _c(
                "div",
                { staticClass: "panel" },
                [
                  _c("span", {
                    staticClass: "task-tit",
                    domProps: { textContent: _vm._s(_vm.testTask.title) },
                  }),
                  _c("el-row", { staticClass: "mt-15" }, [
                    _c("span", { staticClass: "ml-10" }, [
                      _vm._v(" 交卷时间： "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.testTask.examUser.finishTime
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("span", { staticClass: "ml-10" }, [
                      _vm._v(" 用时： "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.changeMinutes(
                              this.testTask.examUser.createTime,
                              this.testTask.examUser.finishTime
                            )
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "mt-20 mb-20 score-body" },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "span",
                          { staticClass: "ml-10" },
                          [
                            _c("font", {
                              staticClass: "score-tit",
                              domProps: {
                                textContent: _vm._s(
                                  Number(_vm.testTask.examUser.score)
                                ),
                              },
                            }),
                            _vm._v("分 "),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "mt-15" }, [
                          _vm._v(" 总分 "),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.testScores) },
                          }),
                          _vm._v("分 "),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("ul", { staticClass: "score-list" }, [
                          _c("li", [
                            _c("span", [_vm._v("答对")]),
                            _c("span", { staticClass: "succe-span" }, [
                              _vm._v(_vm._s(_vm.correctRs) + "道"),
                            ]),
                          ]),
                          _c("li", [
                            _c("span", [_vm._v("答错")]),
                            _c("span", { staticClass: "dange-span" }, [
                              _vm._v(_vm._s(_vm.mistakeRs) + "道"),
                            ]),
                          ]),
                          _c("li", [
                            _c("span", [_vm._v("未批阅")]),
                            _c("span", { staticClass: "audit-span" }, [
                              _vm._v(_vm._s(_vm.notReadRs) + "道"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.testTask.questionList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "panel",
                    attrs: { id: "anchor_" + (index + 1) },
                  },
                  [
                    _c("el-row", { staticClass: "topicTit" }, [
                      _c("span", { staticClass: "top-num" }, [
                        _vm._v(_vm._s(index + 1) + "."),
                      ]),
                      _c("span", { staticClass: "top-type" }, [
                        _vm._v("【 " + _vm._s(item.question.type) + " 】"),
                      ]),
                      _c("span", { staticClass: "top-score" }, [
                        _vm._v(_vm._s(item.score) + " 分"),
                      ]),
                    ]),
                    _c(
                      "el-row",
                      [
                        _c("el-row", {
                          staticClass: "task-title",
                          domProps: { innerHTML: _vm._s(item.question.stem) },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "ul",
                      { staticClass: "label-con" },
                      _vm._l(item.question.itemList, function (label, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "label-list" },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.optionLetterName(index) + "."
                                ),
                              },
                            }),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  label.content.replace(/<[/]?p>/g, "")
                                ),
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                    item.question.type === "单选"
                      ? [
                          _c("el-row", [
                            _c(
                              "span",
                              [
                                _vm._v(" 正确答案是： "),
                                _vm._l(
                                  item.question.itemList,
                                  function (label, index) {
                                    return _c(
                                      "span",
                                      { key: index },
                                      [
                                        label.correctAnswer === true
                                          ? [
                                              _c("span", {
                                                staticClass: "answer-color",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.optionLetterName(index)
                                                  ),
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _c(
                              "span",
                              { staticClass: "ml-15" },
                              [
                                _vm._v(" 学员答案是： "),
                                Boolean(_vm.testTask.questionList[index].answer)
                                  ? _vm._l(
                                      item.question.itemList,
                                      function (ele, idx) {
                                        return _c(
                                          "span",
                                          { key: idx },
                                          [
                                            JSON.parse(
                                              _vm.testTask.questionList[index]
                                                .answer
                                            )[0] === ele.id
                                              ? [
                                                  _c("span", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.optionLetterName(
                                                          idx
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    item.question.type === "多选"
                      ? [
                          _c("el-row", [
                            _c(
                              "span",
                              [
                                _vm._v(" 正确答案是： "),
                                _vm._l(
                                  item.question.itemList,
                                  function (label, index) {
                                    return _c(
                                      "span",
                                      { key: index },
                                      [
                                        label.correctAnswer === true
                                          ? [
                                              _c("span", {
                                                staticClass: "answer-color",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.optionLetterName(index)
                                                  ),
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            _c(
                              "span",
                              { staticClass: "ml-15" },
                              [
                                _vm._v(" 学员答案是： "),
                                Boolean(_vm.testTask.questionList[index].answer)
                                  ? _vm._l(
                                      item.question.itemList,
                                      function (ele, idx) {
                                        return _c(
                                          "span",
                                          { key: idx },
                                          _vm._l(
                                            JSON.parse(
                                              _vm.testTask.questionList[index]
                                                .answer
                                            ),
                                            function (item) {
                                              return _c(
                                                "span",
                                                { key: item.id },
                                                [
                                                  ele.id === item
                                                    ? [
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.optionLetterName(
                                                                idx
                                                              )
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    item.question.type === "填空"
                      ? [
                          _c(
                            "el-tabs",
                            { attrs: { type: "border-card" } },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "学员答案" } },
                                [
                                  [
                                    Boolean(
                                      _vm.testTask.questionList[index].answer
                                    )
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.testTask.questionList[index]
                                                  .answer
                                              )
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                ],
                                2
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "正确答案" } },
                                _vm._l(
                                  JSON.parse(item.question.correctAnswer),
                                  function (label, index) {
                                    return _c("span", { key: index }, [
                                      _vm._v(" " + _vm._s(label) + " "),
                                    ])
                                  }
                                ),
                                0
                              ),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "得分" } },
                                [
                                  _c("el-row", {
                                    staticClass: "myScore",
                                    staticStyle: { margin: "10px 0" },
                                    domProps: {
                                      textContent: _vm._s(item.myScore),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    item.question.type === "简答"
                      ? [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-tabs",
                                { attrs: { type: "border-card" } },
                                [
                                  _c(
                                    "el-tab-pane",
                                    { attrs: { label: "学员答案" } },
                                    [
                                      [
                                        _c("pre", {
                                          domProps: {
                                            textContent: _vm._s(item.answer),
                                          },
                                        }),
                                      ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "el-tab-pane",
                                    { attrs: { label: "正确答案" } },
                                    [
                                      _c("el-row", {
                                        staticStyle: { margin: "10px 0" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            item.question.correctAnswer
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tab-pane",
                                    { attrs: { label: "得分" } },
                                    [
                                      _c("el-row", {
                                        staticClass: "myScore",
                                        staticStyle: { margin: "10px 0" },
                                        domProps: {
                                          textContent: _vm._s(item.myScore),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !item.reviewTime
                            ? [
                                _c(
                                  "el-form",
                                  {
                                    ref: "topicForm",
                                    refInFor: true,
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      model: item,
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "得分：",
                                          prop: "topicScore",
                                          rules: {
                                            required: true,
                                            message: "请输入分数",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "160px" },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.blurFun(item, index)
                                            },
                                            input: _vm.optionChange,
                                          },
                                          model: {
                                            value: item.topicScore,
                                            callback: function ($$v) {
                                              _vm.$set(item, "topicScore", $$v)
                                            },
                                            expression: "item.topicScore",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "评语：" } },
                                      [
                                        _c("my-tinymce", {
                                          attrs: { height: "130px" },
                                          model: {
                                            value: item.topicRemark,
                                            callback: function ($$v) {
                                              _vm.$set(item, "topicRemark", $$v)
                                            },
                                            expression: "item.topicRemark",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _c(
                      "el-row",
                      { staticClass: "mt-15" },
                      [
                        _c("span", [_vm._v("题目解析：")]),
                        _c(
                          "el-row",
                          { staticClass: "mt-15" },
                          [
                            !item.question.analysis.replace(
                              /<([a-z]+?)(?:\s+?[^>]*?)?>\s*?<br><\/\1>/gi,
                              ""
                            )
                              ? [
                                  _c("p", { staticClass: "analysis-span" }, [
                                    _vm._v("无解析内容"),
                                  ]),
                                ]
                              : [
                                  _c("p", {
                                    staticClass: "analysis-span",
                                    domProps: {
                                      innerHTML: _vm._s(item.question.analysis),
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "task-right fl",
              style: { height: _vm.screenHeight + "px" },
            },
            [
              _c(
                "div",
                { staticClass: "task-fixed" },
                [
                  _c("h5", { staticClass: "card-tit" }, [_vm._v("答题卡")]),
                  _c(
                    "ul",
                    { staticClass: "card-list clearfix" },
                    _vm._l(_vm.answerStatusList, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class: _vm.switchCard(item),
                          on: {
                            click: function ($event) {
                              return _vm.skipAnchor(index + 1)
                            },
                          },
                        },
                        [_vm._v(_vm._s(index + 1))]
                      )
                    }),
                    0
                  ),
                  _c("el-row", { staticClass: "card-explain" }, [
                    _c("span", [
                      _c("i", { staticClass: "bg-card-success" }),
                      _vm._v("正确 "),
                    ]),
                    _c("span", [
                      _c("i", { staticClass: "bg-card-error" }),
                      _vm._v("错误 "),
                    ]),
                    _c("span", [
                      _c("i", { staticClass: "bg-card-default" }),
                      _vm._v("未批阅 "),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#212121", margin: "10px 0" },
                          attrs: {
                            size: "small",
                            round: "",
                            type: "primary",
                            disabled: Boolean(_vm.testTask.examUser.reviewTime),
                          },
                          on: { click: _vm.ReadOverPaper },
                        },
                        [_vm._v("完成批阅")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }